import { computed } from 'vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useProvider } from '/~/composables/provider'

const { providerClientName } = useProvider()
const { ewalletLabels } = useCms()

const benefits = computed(() => ({
  items: [
    {
      title: 'Personalised',
      image: {
        desktop: '/images/hiw/symbion/personalised.svg',
      },
      description:
        'It’s all about you. Manage your profile and control your payment methods for a seamless transacting experience.',
    },
    {
      title: 'Convenient',
      image: {
        desktop: '/images/hiw/symbion/convenient.svg',
      },
      description:
        'Instantly access all of your purchased digital eGift Cards and eVouchers anytime from any device.',
    },
    {
      title: 'Secure',
      image: {
        desktop: '/images/hiw/symbion/secure.svg',
      },
      description:
        'Rest assured knowing that our encryption technology has your personal information and activity always protected.',
    },
  ],
}))
const howItWorks = computed(() => ({
  items: [
    {
      title: `Complete ${ewalletLabels.value.yourEwallet} profile`,
      image: {
        desktop: '/images/hiw/symbion/complete-profile.svg',
      },
      description: `Click the ${ewalletLabels.value.ewallet} logo and enter your profile information then add your preferred payment method for an easy payment experience.`,
    },
    {
      title: 'Pay your bills & earn points',
      image: {
        desktop: '/images/hiw/symbion/shop-save.svg',
      },
      description: `Securely pay any bill using your preferred payment method and earn ${providerClientName.value} points for every dollar you spend.`,
    },
    {
      title: 'Redeem points just like cash',
      image: {
        desktop: '/images/hiw/symbion/redeem-store.svg',
      },
      description: `Earn bonus ${providerClientName.value} points along the way and use your points when making other bill payments or shopping rewards.`,
    },
  ],
}))

export function useHowItWorks() {
  return {
    benefits,
    howItWorks,
  }
}
